var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.screen.md && _vm.$route.name === _vm.bonusConfirmation)?_c('AccountAddOn',{attrs:{"subscription-id":Number(_vm.$route.params.subscriptionId),"type":_vm.$route.params.type}}):_c('div',{staticClass:"flex flex-col items-center p-4"},[_c('div',{staticClass:"flex flex-col md:flex-row items-center md:items-start md:justify-center"},_vm._l((_vm.bonuses),function(ref,bonusIndex){
var heading = ref.heading;
var description = ref.description;
var imageUrls = ref.imageUrls;
var type = ref.type;
var buttonCopy = ref.buttonCopy;
return _c('div',{key:bonusIndex,class:((type === 'magazine' ? 'mb-4 md:mr-4' : '') + " bg-dawn-lt3 max-w-94 min-h-79")},[_c('div',{class:("flex " + (type === 'eco-upgrade' ? 'py-2' : ''))},_vm._l((imageUrls),function(src,imageIndex){return _c('BaseImage',{key:imageIndex,attrs:{"src":src,"responsive":"md","alt":"","background-transparent":""}})}),1),_vm._v(" "),_c('div',{staticClass:"px-4 flex flex-col items-center bg-dawn-lt3"},[_c('h2',{staticClass:"mt-5 font-heading text-2xl"},[_vm._v(" "+_vm._s(heading)+" ")]),_vm._v(" "),_c('p',{staticClass:"text-2xs text-center mt-3 mb-4"},[_vm._v("\n            "+_vm._s(description)+"\n            "),_c('span',{staticClass:"inline-block align-middle"},[_c('BaseIconClickable',{staticStyle:{"top":"-0.1rem"},attrs:{"color":"black","label":"info","padding":0,"size":4},on:{"click":function($event){return _vm.$navigate({
                    name: _vm.bonusConfirmation,
                    params: Object.assign({}, _vm.$route.params, {type: type}),
                  })}}},[_c('IconInfo')],1)],1)]),_vm._v(" "),(_vm.subscriptionHasBonus(type))?_c('div',{staticClass:"mt-4 flex items-center"},[_c('BaseIcon',{attrs:{"size":3,"label":"upgraded"}},[_c('IconCheckmarkMedium')],1),_vm._v(" "),_c('span',{staticClass:"ml-2 font-bold text-sm"},[_vm._v("Upgraded")])],1):_c('BaseButtonPill',{staticClass:"mb-6",attrs:{"color":("" + (type === 'magazine' ? 'summer' : 'primary')),"data-cy":("add-button-" + type),"height":"tall"},on:{"click":function($event){return _vm.$navigate({
                name: _vm.bonusConfirmation,
                params: Object.assign({}, _vm.$route.params, {type: type}),
              })}}},[_c('span',{staticClass:"px-1 font-semibold text-sm"},[_vm._v(" "+_vm._s(buttonCopy)+" ")])])],1)])}),0),_vm._v(" "),(_vm.screen.md && _vm.subscriptionHasAnyBonus)?_c('div',{staticClass:"mt-10 w-40"},[_c('BaseButton',{on:{"click":_vm.routeToNextPage}},[_vm._v(" Next ")])],1):_vm._e(),_vm._v(" "),(!_vm.subscriptionHasAnyBonus)?_c('div',{staticClass:"w-full flex flex-col items-center"},[_c('BaseDivider',{staticClass:"md:hidden my-4 w-full",attrs:{"color":"dawn-dk2"}}),_vm._v(" "),_c('BaseLinkStyled',{staticClass:"mt-2 md:mt-10 mb-18 md:mb-6 text-xs",attrs:{"data-cy":"skip-bonus-button"},on:{"click":_vm.routeToNextPage}},[_vm._v("\n        No thanks, maybe later\n      ")])],1):_c('div',{staticClass:"mt-20 md:mt-0"}),_vm._v(" "),_c('Portal',{attrs:{"to":"modal"}},[_c('BaseModal',{attrs:{"size":"sm","height":"md","bg-color":"dawn","no-vertical-padding":"","open":_vm.$route.name === _vm.bonusConfirmation,"dismissible":""},on:{"dismiss":function($event){return _vm.$navigate({
            name: _vm.bonusView,
            params: Object.assign({}, _vm.$route.params),
          })}}},[_c('RouterView')],1)],1),_vm._v(" "),_c('Portal',{attrs:{"to":"fixedBottom"}},[_c('BaseTransitionFixedBottom',[(!_vm.navigating)?_c('div',{staticClass:"md:hidden"},[(_vm.subscriptionHasAnyBonus)?_c('BaseButton',{on:{"click":_vm.routeToNextPage}},[_vm._v(" Next ")]):_vm._e()],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }