<script>
  import { get, call } from 'vuex-pathify'
  import AccountAddOn from '@/views/AccountAddOn'
  import screen from '@/helpers/screen'

  export default {
    components: {
      AccountAddOn,
    },
    data() {
      return {
        bonuses: [
          // Uncomment if magazine is re-enabled
          // {
          //   heading: 'Premium Magazine Upgrade',
          //   description:
          //     'Get the coffee-table worthy, premium magazine — a seasonal guide to conscious living.',
          //   imageUrls: ['/static/magazine/magazine-banner-spring-22.jpg'],
          //   type: 'magazine',
          //   buttonCopy: 'Add for only $7.95/season',
          // },
          {
            heading: 'Eco-Shipping Upgrade',
            description:
              'Make your Alltrue box shipment carbon neutral through our partnership with Grow Ahead.',
            imageUrls: ['/static/illustrations/IllustrationBonusEcoUpgrade.png'],
            type: 'eco-upgrade',
            buttonCopy: 'Add for only $1/season',
          },
        ],
      }
    },
    computed: {
      ...get('general', ['navigating']),
      ...get('customize', ['selectedSubscription', 'isSelectedSubscriptionQuarterly']),
      ...get('config', ['currentBox']),
      screen,
      subscriptionHasAnyBonus() {
        return this.subscriptionHasBonus('magazine') || this.subscriptionHasBonus('eco-upgrade')
      },
      bonusView() {
        return this.$route.path.includes('subscribe') ? 'SubscribeBonuses' : 'CustomizeBonuses'
      },
      bonusConfirmation() {
        return this.$route.path.includes('subscribe')
          ? 'SubscribeBonusConfirmation'
          : 'CustomizeBonusConfirmation'
      },
      customizationIsOpen() {
        return this.isSelectedSubscriptionQuarterly
          ? this.currentBox?.choiceQuarterlyEnabled
          : this.currentBox?.choiceAnnualEnabled
      },
    },
    /* loading account to refresh subscriptions **/
    async mounted() {
      await this.fetchProfile()
    },
    methods: {
      ...call('account', ['fetchProfile']),
      subscriptionHasBonus(type) {
        return this.selectedSubscription?.addOns.includes(type)
      },
      routeToNextPage() {
        if (this.$route.path.includes('subscribe')) {
          const startDate = this.isSelectedSubscriptionQuarterly
            ? this.currentBox.choiceQuarterlyStartDate
            : this.currentBox.choiceAnnualStartDate
          const startDateIsImminent = startDate
            ? new Date() < new Date(startDate.replace(/-/g, '/'))
            : null
          if (!this.customizationIsOpen && !startDateIsImminent) {
            this.$navigate({ name: 'SubscribeWelcome' })
          } else {
            const type = this.customizationIsOpen ? 'customizeStart' : 'customizeComingSoon'
            this.$navigate({
              name: 'SubscribeInterstitial',
              params: {
                subscribe: 'subscribe',
                type,
                subscriptionId: this.$route.params.subscriptionId,
              },
            })
          }
        } else {
          this.$navigate({
            name: 'CustomizeSubscription',
            params: { subscriptionId: this.$route.params.subscriptionId, ...this.$route.params },
          })
        }
      },
    },
  }
</script>

<template>
  <div>
    <AccountAddOn
      v-if="!screen.md && $route.name === bonusConfirmation"
      :subscription-id="Number($route.params.subscriptionId)"
      :type="$route.params.type"
    />
    <div v-else class="flex flex-col items-center p-4">
      <div class="flex flex-col md:flex-row items-center md:items-start md:justify-center">
        <div
          v-for="({ heading, description, imageUrls, type, buttonCopy }, bonusIndex) in bonuses"
          :key="bonusIndex"
          :class="`${type === 'magazine' ? 'mb-4 md:mr-4' : ''} bg-dawn-lt3 max-w-94 min-h-79`"
        >
          <div :class="`flex ${type === 'eco-upgrade' ? 'py-2' : ''}`">
            <BaseImage
              v-for="(src, imageIndex) in imageUrls"
              :key="imageIndex"
              :src="src"
              responsive="md"
              alt=""
              background-transparent
            />
          </div>
          <div class="px-4 flex flex-col items-center bg-dawn-lt3">
            <h2 class="mt-5 font-heading text-2xl"> {{ heading }} </h2>
            <p class="text-2xs text-center mt-3 mb-4">
              {{ description }}
              <span class="inline-block align-middle">
                <BaseIconClickable
                  color="black"
                  label="info"
                  :padding="0"
                  :size="4"
                  style="top: -0.1rem"
                  @click="
                    $navigate({
                      name: bonusConfirmation,
                      params: { ...$route.params, type: type },
                    })
                  "
                >
                  <IconInfo />
                </BaseIconClickable>
              </span>
            </p>
            <div v-if="subscriptionHasBonus(type)" class="mt-4 flex items-center">
              <BaseIcon :size="3" label="upgraded">
                <IconCheckmarkMedium />
              </BaseIcon>
              <span class="ml-2 font-bold text-sm">Upgraded</span>
            </div>
            <BaseButtonPill
              v-else
              :color="`${type === 'magazine' ? 'summer' : 'primary'}`"
              :data-cy="`add-button-${type}`"
              class="mb-6"
              height="tall"
              @click="
                $navigate({
                  name: bonusConfirmation,
                  params: { ...$route.params, type: type },
                })
              "
            >
              <span class="px-1 font-semibold text-sm"> {{ buttonCopy }} </span>
            </BaseButtonPill>
          </div>
        </div>
      </div>
      <div v-if="screen.md && subscriptionHasAnyBonus" class="mt-10 w-40">
        <BaseButton @click="routeToNextPage"> Next </BaseButton>
      </div>
      <div v-if="!subscriptionHasAnyBonus" class="w-full flex flex-col items-center">
        <BaseDivider color="dawn-dk2" class="md:hidden my-4 w-full" />
        <BaseLinkStyled
          class="mt-2 md:mt-10 mb-18 md:mb-6 text-xs"
          data-cy="skip-bonus-button"
          @click="routeToNextPage"
        >
          No thanks, maybe later
        </BaseLinkStyled>
      </div>
      <div v-else class="mt-20 md:mt-0" />
      <Portal to="modal">
        <BaseModal
          size="sm"
          height="md"
          bg-color="dawn"
          no-vertical-padding
          :open="$route.name === bonusConfirmation"
          dismissible
          @dismiss="
            $navigate({
              name: bonusView,
              params: { ...$route.params },
            })
          "
        >
          <RouterView />
        </BaseModal>
      </Portal>
      <Portal to="fixedBottom">
        <BaseTransitionFixedBottom>
          <div v-if="!navigating" class="md:hidden">
            <BaseButton v-if="subscriptionHasAnyBonus" @click="routeToNextPage"> Next </BaseButton>
          </div>
        </BaseTransitionFixedBottom>
      </Portal>
    </div>
  </div>
</template>
